import { useState } from "react";
import { AuthAdapter } from "@web3auth/auth-adapter";
import {
  UX_MODE,
  WALLET_ADAPTERS,
  CHAIN_NAMESPACES,
  IProvider,
  WEB3AUTH_NETWORK,
} from "@web3auth/base";
// import { SolanaPrivateKeyProvider } from "@web3auth/solana-provider";
import { CommonPrivateKeyProvider } from "@web3auth/base-provider";
import { Web3Auth, Web3AuthOptions } from "@web3auth/modal";

// import * as RPC from "./solRPC";
// import * as RPC from "./ethRPC";
import RPC, { rpcTarget } from "../components/web3Auth/tonRPC";
import { atom, useRecoilState } from "recoil";
import useUserStore from "store/user-store/useUserStore";

const clientId = process.env.REACT_APP_WEB3AUTH_CLIENT_ID ?? "";
const verifier = process.env.REACT_APP_VERIFIER ?? "";

const chainConfig = {
  rpcTarget,
  chainNamespace: CHAIN_NAMESPACES.OTHER,
  chainId: "testnet",
  displayName: "TON Testnet",
  blockExplorerUrl: "https://testnet.tonscan.org",
  ticker: "TON",
  tickerName: "Toncoin",
};

// telegram oauth
const authAdapter = new AuthAdapter({
  adapterSettings: {
    uxMode: UX_MODE.REDIRECT,
    loginConfig: {
      jwt: {
        verifier, // Pass the Verifier name here
        typeOfLogin: "jwt", // Pass on the login provider of the verifier you've created
        clientId,
      },
    },
  },
});

const privateKeyProvider = new CommonPrivateKeyProvider({
  config: { chainConfig: chainConfig },
});

const web3AuthOptions: Web3AuthOptions = {
  clientId,
  web3AuthNetwork: WEB3AUTH_NETWORK.SAPPHIRE_DEVNET,
  privateKeyProvider,
};
const web3auth = new Web3Auth(web3AuthOptions);

const useWeb3AuthState = atom({
  key: "authWebState",
  default: {
    loggedIn: false,
  },
});

function useWeb3AuthWeb() {
  const [provider, setProvider] = useState<IProvider | null>(null);
  const [authWebState, setAuthWebState] = useRecoilState(useWeb3AuthState);
  const { setTonBalance, setWalletAddress } = useUserStore();

  const rpc = new RPC(provider);
  console.debug("useWeb3AuthWeb loggedIn: ", authWebState.loggedIn);

  const initWeb3Auth = async () => {
    try {
      web3auth.configureAdapter(authAdapter);
      await web3auth.init();
      setProvider(web3auth.provider);

      if (web3auth.connected) {
        setAuthWebState((prev) => ({ ...prev, loggedIn: true }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const loginWithWeb3Auth = async (token: string) => {
    const web3authProvider = await web3auth?.connectTo(WALLET_ADAPTERS.AUTH, {
      loginProvider: "jwt",
      extraLoginOptions: {
        id_token: token,
        verifierIdField: "sub",
      },
    });
    setProvider(web3authProvider!);
  };

  const getUserInfo = async () => {
    const user = await web3auth.getUserInfo();
    console.debug("user: ", user);
  };

  const logoutWeb = async () => {
    await web3auth.logout();
    setProvider(null);
    setAuthWebState((prev) => ({ ...prev, loggedIn: false }));
  };

  // Check the RPC file for the implementation
  const getAccountsWeb = async () => {
    if (!provider) {
      console.debug("provider not initialized yet");
      return;
    }
    const address = await rpc.getAccounts();
    setWalletAddress(address);
  };

  const getBalanceWeb = async () => {
    if (!provider) {
      console.debug("provider not initialized yet");
      return;
    }
    const balance = await rpc.getBalance();
    setTonBalance(balance);
  };

  const signMessage = async () => {
    if (!provider) {
      uiConsole("provider not initialized yet");
      return;
    }
    const signedMessage = await rpc.sendTransaction();
    uiConsole(signedMessage);
  };

  const sendTransaction = async () => {
    if (!provider) {
      console.debug("provider not initialized yet");
      return;
    }
    console.debug("Sending Transaction...");
    const transactionReceipt = await rpc.sendTransaction();
    console.debug("transactionReceipt: ", transactionReceipt);
  };

  function uiConsole(...args: any[]): void {
    const el = document.querySelector("#console>p");
    if (el) {
      el.innerHTML = JSON.stringify(args || {}, null, 2);
      console.debug(...args);
    }
  }

  return {
    provider,
    setProvider,
    authWebState,
    setAuthWebState,
    loginWithWeb3Auth,
    getUserInfo,
    logoutWeb,
    getAccountsWeb,
    getBalanceWeb,
    signMessage,
    sendTransaction,
    initWeb3Auth,
  };
}

export default useWeb3AuthWeb;
