import SvgIcon from "@mui/material/SvgIcon";

export default function QuestIcon(props: any) {
  return (
    <SvgIcon {...props} sx={{ cursor: "pointer" }}>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.91406 24H17.0703C18.6371 24 19.8828 22.6822 19.8828 21.1406V7.07813H24.1016V2.8125C24.1016 1.2616 22.84 0 21.2891 0H4.32031C2.76941 0 1.50781 1.2616 1.50781 2.8125V19.7344H0.101562V21.1406C0.101562 22.6826 1.34778 24 2.91406 24ZM22.6953 2.8125V5.67188H19.8828V2.8125C19.8828 2.03705 20.5136 1.40625 21.2891 1.40625C22.0645 1.40625 22.6953 2.03705 22.6953 2.8125ZM2.91406 2.8125C2.91406 2.03705 3.54486 1.4057 4.32031 1.4057H18.8549C18.6148 1.81989 18.4766 2.30035 18.4766 2.8125V21.1406C18.4766 21.9283 17.8326 22.5938 17.0703 22.5938C16.308 22.5938 15.6641 21.9283 15.6641 21.1406V19.7344H2.91406V2.8125ZM14.2578 21.1406C14.2578 21.6574 14.3955 22.1561 14.6531 22.5938H2.91406C2.15179 22.5938 1.50781 21.9283 1.50781 21.1406H14.2578Z"
          fill={props.isfocus ? "#FFB800" : "white"}
        />
        <path
          d="M12.6838 5.67188H15.6641V4.26562H12.6871C12.4061 3.44879 11.6345 2.8125 10.6953 2.8125C9.75525 2.8125 8.98401 3.45044 8.70349 4.26562H5.72656V5.67188H8.70679C8.99719 6.49017 9.77869 7.07812 10.6953 7.07812C11.6119 7.07812 12.3934 6.49017 12.6838 5.67188ZM10.6953 5.67188C10.3077 5.67188 9.99219 5.35638 9.99219 4.96875C9.99219 4.56921 10.3207 4.21875 10.6953 4.21875C11.0699 4.21875 11.3984 4.56921 11.3984 4.96875C11.3984 5.35638 11.0829 5.67188 10.6953 5.67188Z"
          fill={props.isfocus ? "#FFB800" : "white"}
        />
        <path d="M4.32031 8.48438H9.99219V9.89062H4.32031V8.48438Z" fill="white" />
        <path d="M11.3984 8.48438H17.0703V9.89062H11.3984V8.48438Z" fill="white" />
        <path d="M4.32031 11.2969H9.99219V12.7031H4.32031V11.2969Z" fill="white" />
        <path d="M11.3984 11.2969H17.0703V12.7031H11.3984V11.2969Z" fill="white" />
        <path d="M4.32031 14.1094H9.99219V15.5156H4.32031V14.1094Z" fill="white" />
        <path d="M11.3984 14.1094H17.0703V15.5156H11.3984V14.1094Z" fill="white" />
        <path d="M4.32031 16.9219H9.99219V18.3281H4.32031V16.9219Z" fill="white" />
        <path d="M11.3984 16.9219H17.0703V18.3281H11.3984V16.9219Z" fill="white" />
      </svg>
    </SvgIcon>
  );
}
