import { IconButton, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { HeaderMenu, MobileMenu } from "components/header/headerMenu/HeaderMenu";
import {
  HeaderRight,
  HeaderContent,
  HeaderOptionalContent,
  HeaderWrapper,
  HeaderLeft,
  HeaderTitle,
  TelegramBox,
} from "./styled";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useHeader } from "hooks/useHeader";
import useWeb3Auth from "hooks/useWeb3AuthMiniApp";

export const Header = () => {
  const { title, options } = useHeader();
  const { authState } = useWeb3Auth();
  const navigate = useNavigate();
  const location = useLocation();
  const matches = useMediaQuery("(min-width:900px)");
  const [mobileMenu, setMobileMenu] = useState(false);
  const scriptRef = useRef<null | HTMLDivElement>(null);
  const topRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    topRef.current?.scrollIntoView();
  }, [location]);

  useEffect(() => {
    if (authState.isModelToggle && scriptRef.current) {
      const script = document.createElement("script");
      script.src = "https://telegram.org/js/telegram-widget.js?22";
      script.setAttribute("data-telegram-login", process.env.REACT_APP_TG_BOT_NAME!);
      script.setAttribute("data-size", "large");
      script.setAttribute("data-userpic", "false");
      script.setAttribute("data-auth-url", process.env.REACT_APP_TG_CALLBACK_URL!);
      script.setAttribute("data-request-access", "write");
      scriptRef.current.appendChild(script);
    }
  }, [authState.isModelToggle]);

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <>
      <HeaderWrapper position="static" ref={topRef}>
        <HeaderContent>
          <HeaderOptionalContent>
            {/* <AppLogo /> */}
            <HeaderLeft>
              {options.showBackButton && (
                <IconButton onClick={options.onBackButtonClick || handleBackClick}>
                  <ArrowBackIcon />
                </IconButton>
              )}
              <HeaderTitle>{title}</HeaderTitle>
            </HeaderLeft>

            <HeaderRight>
              {options.showAvatar && <HeaderMenu />}
              {/* <IconButton onClick={() => setMobileMenu(true)}>
                <MenuRoundedIcon style={{ width: 38, height: 38, color: "#FFB800" }} />
              </IconButton> */}
            </HeaderRight>
          </HeaderOptionalContent>
          <MobileMenu showMenu={mobileMenu && !matches} closeMenu={() => setMobileMenu(false)} />
        </HeaderContent>
      </HeaderWrapper>

      {/* Telegram authentication */}
      {authState.isModelToggle && <TelegramBox ref={scriptRef} />}
      <Outlet />
    </>
  );
};
