import SvgIcon from "@mui/material/SvgIcon";

export default function HowItWorksIcon(props: any) {
  return (
    <SvgIcon {...props} sx={{ cursor: "pointer" }}>
      <svg
        width="29"
        height="28"
        viewBox="0 0 29 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M28.0547 5.57812H25.5938V3.11719C25.5938 2.66416 25.2265 2.29688 24.7734 2.29688H20.2217C18.8759 0.882766 16.9766 0 14.875 0C12.7734 0 10.8741 0.882766 9.52826 2.29688H4.97656C4.52353 2.29688 4.15625 2.66416 4.15625 3.11719V5.57812H1.69531C1.24228 5.57812 0.875 5.94541 0.875 6.39844V24.9922C0.875 25.4452 1.24228 25.8125 1.69531 25.8125H10.8871C11.2451 27.0736 12.407 28 13.7812 28H15.9688C17.343 28 18.5049 27.0736 18.8629 25.8125H28.0547C28.5077 25.8125 28.875 25.4452 28.875 24.9922V6.39844C28.875 5.94541 28.5077 5.57812 28.0547 5.57812ZM23.9531 3.9375V20.8906H17.6767C16.9545 20.8906 16.2726 21.0979 15.6953 21.4627V14.7198C19.3816 14.3104 22.2578 11.1764 22.2578 7.38281C22.2578 6.13916 21.9483 4.96666 21.4029 3.9375H23.9531ZM14.875 1.64062C18.0412 1.64062 20.6172 4.21657 20.6172 7.38281C20.6172 10.5491 18.0412 13.125 14.875 13.125C11.7088 13.125 9.13281 10.5491 9.13281 7.38281C9.13281 4.21657 11.7088 1.64062 14.875 1.64062ZM5.79688 3.9375H8.34712C7.80172 4.96666 7.49219 6.13916 7.49219 7.38281C7.49219 11.1764 10.3684 14.3104 14.0547 14.7198V21.4627C13.4773 21.0979 12.7955 20.8906 12.0733 20.8906H5.79688V3.9375ZM27.2344 24.1719H18.1562C17.7032 24.1719 17.3359 24.5392 17.3359 24.9922C17.3359 25.7461 16.7226 26.3594 15.9688 26.3594H13.7812C13.0274 26.3594 12.4141 25.7461 12.4141 24.9922C12.4141 24.5392 12.0468 24.1719 11.5938 24.1719H2.51562V7.21875H4.15625V21.7109C4.15625 22.164 4.52353 22.5312 4.97656 22.5312H12.0733C13.0238 22.5312 13.8487 23.1753 14.0792 24.0974L14.0799 24.0973C14.1605 24.4198 14.4316 24.6704 14.7748 24.7126C14.8087 24.7168 14.8424 24.7188 14.8758 24.7188C15.2474 24.7188 15.5787 24.4662 15.6709 24.0974C15.9014 23.1753 16.7263 22.5312 17.6768 22.5312H24.7734C25.2265 22.5312 25.5938 22.164 25.5938 21.7109V7.21875H27.2344V24.1719Z"
          fill={props.isfocus ? "#FFB800" : "white"}
        />
        <path
          d="M8.3125 15.9688H9.95312C10.4062 15.9688 10.7734 15.6015 10.7734 15.1484C10.7734 14.6954 10.4062 14.3281 9.95312 14.3281H8.3125C7.85947 14.3281 7.49219 14.6954 7.49219 15.1484C7.49219 15.6015 7.85947 15.9688 8.3125 15.9688Z"
          fill={props.isfocus ? "#FFB800" : "white"}
        />
        <path
          d="M8.3125 19.25H11.5938C12.0468 19.25 12.4141 18.8827 12.4141 18.4297C12.4141 17.9767 12.0468 17.6094 11.5938 17.6094H8.3125C7.85947 17.6094 7.49219 17.9767 7.49219 18.4297C7.49219 18.8827 7.85947 19.25 8.3125 19.25Z"
          fill={props.isfocus ? "#FFB800" : "white"}
        />
        <path
          d="M21.4375 14.3281H19.7969C19.3438 14.3281 18.9766 14.6954 18.9766 15.1484C18.9766 15.6015 19.3438 15.9688 19.7969 15.9688H21.4375C21.8905 15.9688 22.2578 15.6015 22.2578 15.1484C22.2578 14.6954 21.8905 14.3281 21.4375 14.3281Z"
          fill={props.isfocus ? "#FFB800" : "white"}
        />
        <path
          d="M21.4375 17.6094H18.1562C17.7032 17.6094 17.3359 17.9767 17.3359 18.4297C17.3359 18.8827 17.7032 19.25 18.1562 19.25H21.4375C21.8905 19.25 22.2578 18.8827 22.2578 18.4297C22.2578 17.9767 21.8905 17.6094 21.4375 17.6094Z"
          fill={props.isfocus ? "#FFB800" : "white"}
        />
        <path
          d="M14.875 11.4844C15.328 11.4844 15.6953 11.1171 15.6953 10.6641V6.83594C15.6953 6.38291 15.328 6.01562 14.875 6.01562C14.422 6.01562 14.0547 6.38291 14.0547 6.83594V10.6641C14.0547 11.1171 14.422 11.4844 14.875 11.4844Z"
          fill={props.isfocus ? "#FFB800" : "white"}
        />
        <path
          d="M14.875 4.92188C15.328 4.92188 15.6953 4.55461 15.6953 4.10156C15.6953 3.64852 15.328 3.28125 14.875 3.28125C14.422 3.28125 14.0547 3.64852 14.0547 4.10156C14.0547 4.55461 14.422 4.92188 14.875 4.92188Z"
          fill={props.isfocus ? "#FFB800" : "white"}
        />
      </svg>
    </SvgIcon>
  );
}
