import SvgIcon from "@mui/material/SvgIcon";

export default function ExplorerIcon(props: any) {
  return (
    <SvgIcon {...props} sx={{ cursor: "pointer" }}>
      <svg
        width="29"
        height="28"
        viewBox="0 0 29 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M28.0921 2.09025C28.0314 0.978021 27.1469 0.0935632 26.0347 0.0328603C23.7904 -0.0897486 19.3988 0.0295246 15.1995 2.20154C13.0587 3.30885 10.7947 5.18621 8.98786 7.35226C8.9656 7.37895 8.94394 7.4058 8.92185 7.43254L4.82139 7.74929C4.14644 7.80146 3.54527 8.15524 3.17197 8.71983L0.374497 12.9511C0.091381 13.3793 0.0463733 13.9152 0.254021 14.3846C0.461723 14.854 0.888503 15.1811 1.39573 15.2597L4.85038 15.7948C4.83818 15.8619 4.82593 15.929 4.81472 15.9962C4.69972 16.6842 4.92765 17.3915 5.42454 17.8883L10.2366 22.7004C10.6485 23.1124 11.2052 23.3395 11.7756 23.3395C11.8931 23.3395 12.0112 23.3298 12.1288 23.3102C12.1959 23.299 12.263 23.2867 12.3302 23.2745L12.8652 26.7292C12.9438 27.2365 13.271 27.6633 13.7403 27.8709C13.9356 27.9573 14.1422 28 14.348 28C14.6369 27.9999 14.9238 27.9158 15.1738 27.7505L19.4051 24.953C19.9697 24.5796 20.3234 23.9785 20.3755 23.3036L20.6922 19.2032C20.719 19.1811 20.7459 19.1594 20.7726 19.1372C22.9387 17.3303 24.8161 15.0663 25.9234 12.9255C28.0955 8.72601 28.2145 4.33434 28.0921 2.09025ZM18.5003 23.5844L14.4528 26.2604L13.9313 22.8936C15.6419 22.3906 17.3341 21.5851 18.9462 20.5059L18.7399 23.1771C18.7271 23.3438 18.6397 23.4922 18.5003 23.5844ZM11.3967 21.5403L6.58457 16.7282C6.46179 16.6054 6.40503 16.4329 6.43287 16.2667C6.57593 15.4112 6.79823 14.5969 7.07342 13.8297L14.2937 21.05C13.3723 21.38 12.547 21.5769 11.8582 21.692C11.6918 21.7197 11.5194 21.6631 11.3967 21.5403ZM4.94778 9.38505L7.61892 9.17871C6.53972 10.7908 5.73418 12.483 5.23122 14.1936L1.86445 13.6722L4.54052 9.62468C4.63272 9.48523 4.7812 9.3979 4.94778 9.38505ZM19.7218 17.8772C18.3966 18.9826 17.1102 19.7821 15.9244 20.3607L7.76395 12.2002C8.52181 10.6523 9.44674 9.36339 10.2477 8.40319C11.9198 6.39863 13.9993 4.66936 15.9533 3.65874C19.8073 1.66522 23.8679 1.55787 25.9453 1.67096C26.2203 1.68595 26.439 1.90464 26.454 2.17966C26.5673 4.25713 26.4597 8.31766 24.4662 12.1717C23.4556 14.1256 21.7263 16.2052 19.7218 17.8772Z"
          fill={props.isfocus ? "#FFB800" : "white"}
        />
        <path
          d="M19.305 12.9195C20.3551 12.9194 21.4056 12.5196 22.2051 11.7201C22.9798 10.9455 23.4064 9.91551 23.4064 8.82002C23.4064 7.72452 22.9798 6.69454 22.2051 5.91989C20.606 4.32067 18.0039 4.32078 16.4049 5.91989C15.6302 6.69454 15.2036 7.72452 15.2036 8.82002C15.2036 9.91551 15.6302 10.9455 16.4049 11.7201C17.2045 12.5198 18.2545 12.9196 19.305 12.9195ZM17.5648 7.07992C18.0446 6.60015 18.6747 6.36029 19.3049 6.36029C19.9351 6.36029 20.5653 6.60015 21.045 7.07992C21.5098 7.54471 21.7657 8.16267 21.7657 8.81996C21.7657 9.47725 21.5098 10.0953 21.045 10.5601C20.0855 11.5195 18.5243 11.5195 17.5648 10.5601C17.1 10.0953 16.844 9.4773 16.844 8.82002C16.844 8.16273 17.1001 7.54471 17.5648 7.07992Z"
          fill={props.isfocus ? "#FFB800" : "white"}
        />
        <path
          d="M0.961784 23.0746C1.17173 23.0746 1.38167 22.9946 1.5418 22.8343L4.22 20.1561C4.54036 19.8358 4.54036 19.3164 4.22 18.9961C3.89969 18.6757 3.38028 18.6757 3.05992 18.9961L0.381771 21.6743C0.0614124 21.9946 0.0614124 22.514 0.381771 22.8343C0.541895 22.9945 0.751839 23.0746 0.961784 23.0746Z"
          fill={props.isfocus ? "#FFB800" : "white"}
        />
        <path
          d="M6.67442 21.4505C6.35412 21.1301 5.8347 21.1301 5.51434 21.4505L0.36531 26.5996C0.0449515 26.9199 0.0449515 27.4393 0.36531 27.7596C0.525489 27.9198 0.735379 27.9999 0.945323 27.9999C1.15527 27.9999 1.36521 27.9198 1.52534 27.7596L6.67436 22.6106C6.99478 22.2902 6.99478 21.7708 6.67442 21.4505Z"
          fill={props.isfocus ? "#FFB800" : "white"}
        />
        <path
          d="M7.96876 23.9049L5.29056 26.5831C4.9702 26.9035 4.9702 27.4228 5.29056 27.7432C5.45074 27.9034 5.66068 27.9835 5.87057 27.9835C6.08046 27.9835 6.29046 27.9034 6.45058 27.7432L9.12879 25.065C9.44914 24.7446 9.44914 24.2253 9.12879 23.9049C8.80848 23.5845 8.28906 23.5845 7.96876 23.9049Z"
          fill={props.isfocus ? "#FFB800" : "white"}
        />
      </svg>
    </SvgIcon>
  );
}
