import { useEffect } from "react";
import { Button, Drawer, styled } from "@mui/material";
import { AppLogo } from "components/appLogo";
import { LogoContainer, CloseMenuButton, DrawerContent, HeaderTypography } from "./styled";
import { UserAvatar } from "components/UserAvatar";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "consts";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import useUserStore from "store/user-store/useUserStore";
import walletIcon from "assets/icons/tg-logo.svg";
import useWeb3Auth, { LaunchParamsLoader } from "hooks/useWeb3AuthMiniApp";
import useWeb3AuthWeb from "hooks/useWeb3AuthWeb";

interface MenuProps {
  closeMenu?: () => void;
  showMenu?: boolean;
}

const MobileMenu: React.FC<MenuProps> = ({ closeMenu, showMenu }) => {
  return (
    <Drawer
      anchor="left"
      open={showMenu}
      onClose={closeMenu}
      sx={{
        ".MuiDrawer-paper": {
          background: "#000",
          width: "70vw",
        },
      }}>
      <LogoContainer>
        <AppLogo />
      </LogoContainer>

      <CloseMenuButton onClick={closeMenu}>
        <CloseRoundedIcon style={{ width: 38, height: 38 }} />
      </CloseMenuButton>

      <DrawerContent>
        <HeaderTypography>EDIT TELEGRAM</HeaderTypography>
        <HeaderTypography>DISCONNECT</HeaderTypography>
      </DrawerContent>
    </Drawer>
  );
};

const HeaderMenu: React.FC<MenuProps> = (props) => {
  const navigate = useNavigate();
  const { initWeb3Auth, loginWithWeb3Auth, getBalanceWeb, getAccountsWeb, authWebState } =
    useWeb3AuthWeb();
  const { login, authState, initializeWeb3Auth, getAccounts, getBalance } = useWeb3Auth();
  const { getUser, tgUserId, setTgData, walletAddress } = useUserStore();
  console.debug("walletAddress: ", walletAddress);

  // only initialize once
  useEffect(() => {
    if (!tgUserId) {
      initWeb3Auth();
    } else {
      initializeWeb3Auth();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const url = new URL(window.location.href);
    const jwtToken = url.searchParams.get("token");
    console.debug("jwtToken: ", jwtToken);
    if (jwtToken) {
      loginWithWeb3Auth(jwtToken);
    }
  }, [loginWithWeb3Auth]);

  useEffect(() => {
    if (!walletAddress) {
      if (!tgUserId && authWebState.loggedIn) {
        getAccountsWeb();
        getBalanceWeb();
      } else if (!authState.isError && authState.loggedIn) {
        getAccounts();
        getBalance();
      }
    }
  }, [
    getAccounts,
    getBalance,
    authState,
    tgUserId,
    getAccountsWeb,
    getBalanceWeb,
    authWebState,
    walletAddress,
  ]);

  useEffect(() => {
    if (tgUserId && walletAddress) {
      getUser(tgUserId, walletAddress, walletAddress);
    }
  }, [getUser, tgUserId, authState, walletAddress]);

  useEffect(() => {
    if (
      !window.location.search.includes("testnet") &&
      (authState.loggedIn || authWebState.loggedIn)
    ) {
      navigate({
        pathname: ROUTES.explorer,
        search: "?testnet",
      });
    }
  });

  return authState.loggedIn || authWebState.loggedIn ? (
    <UserAvatar />
  ) : (
    <CustomTonConnectButton onClick={login}>
      <img src={walletIcon} alt="wallet" />
      Connect
      {tgUserId && <LaunchParamsLoader onLoad={setTgData} />}
    </CustomTonConnectButton>
  );
};

const CustomTonConnectButton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: 40,
  minWidth: 40,
  background: theme.palette.primary.main,
  borderRadius: 0,
  paddingInline: 10,
  margin: 0,
  color: "#000",
  fontFamily: "Bungee, sans-serif",
  fontSize: "12px",
  fontWeight: 400,
  "&:hover": {
    background: theme.palette.primary.main,
  },
  "& img": {
    width: 20,
    height: 20,
    marginRight: 6,
  },
}));

export { HeaderMenu, MobileMenu };
