import { useEffect, useState } from "react";
import { CHAIN_NAMESPACES, IProvider, WEB3AUTH_NETWORK } from "@web3auth/base";
// import { SolanaPrivateKeyProvider } from "@web3auth/solana-provider";
import { CommonPrivateKeyProvider } from "@web3auth/base-provider";
import { Web3Auth, Web3AuthOptions, decodeToken } from "@web3auth/single-factor-auth";
import { useLaunchParams } from "@telegram-apps/sdk-react";
// import * as RPC from "./solRPC";
// import * as RPC from "./ethRPC";
import RPC, { rpcTarget } from "../components/web3Auth/tonRPC";
import { atom, useRecoilState } from "recoil";
import useUserStore from "store/user-store/useUserStore";
import axiosService from "services/axios";

const clientId = process.env.REACT_APP_WEB3AUTH_CLIENT_ID ?? "";
const verifier = process.env.REACT_APP_VERIFIER ?? "";

interface IWeb3Auth {
  loggedIn: boolean;
  isModelToggle: boolean;
  web3authSfa: any;
  web3AuthInitialized: boolean;
  isError: boolean;
}

const useWeb3AuthState = atom<IWeb3Auth>({
  key: "authState",
  default: {
    loggedIn: false,
    isModelToggle: false,
    web3authSfa: null,
    web3AuthInitialized: false,
    isError: false,
  },
});

export function LaunchParamsLoader({ onLoad }: any) {
  const params = useLaunchParams();

  useEffect(() => {
    if (params) {
      onLoad(params); // Pass params back to MyComponent
    }
  }, [params, onLoad]);

  return null; // This component only loads the hook, no DOM rendering needed
}

function useWeb3Auth() {
  const { tgData, setTonBalance, setWalletAddress } = useUserStore();
  const [provider, setProvider] = useState<IProvider | null>(null);
  const [web3authSfa, setWeb3authSfa] = useState<any>(null);
  const [authState, setAuthState] = useRecoilState(useWeb3AuthState);

  const rpc = new RPC(provider);

  useEffect(() => {
    if (!web3authSfa) {
      initializeWeb3Auth();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [web3authSfa]);

  const initializeWeb3Auth = async () => {
    try {
      console.log("Fetching TON Testnet RPC endpoint...");

      const chainConfig = {
        rpcTarget,
        chainNamespace: CHAIN_NAMESPACES.OTHER,
        chainId: "testnet",
        displayName: "TON Testnet",
        blockExplorerUrl: "https://testnet.tonscan.org",
        ticker: "TON",
        tickerName: "Toncoin",
      };

      const privateKeyProvider = new CommonPrivateKeyProvider({
        config: { chainConfig },
      });

      // Initialize Web3Auth
      const web3AuthOptions: Web3AuthOptions = {
        clientId,
        web3AuthNetwork: WEB3AUTH_NETWORK.SAPPHIRE_DEVNET,
        usePnPKey: false,
        privateKeyProvider,
      };
      const web3authInstance = new Web3Auth(web3AuthOptions);

      console.log("Initializing Web3Auth...");
      await web3authInstance.init();
      console.log("Web3Auth initialized.");

      setAuthState((prev) => ({
        ...prev,
        web3AuthInitialized: true,
      }));

      setWeb3authSfa(web3authInstance);
      setProvider(web3authInstance.provider);
    } catch (error) {
      console.error("Error fetching TON Testnet RPC endpoint: ", error);
    }
  };

  const connectWeb3Auth = async () => {
    if (web3authSfa && authState.web3AuthInitialized && tgData?.initDataRaw) {
      try {
        if (web3authSfa.status === "connected") {
          await web3authSfa.logout();
        }

        const isMocked = tgData.initData?.user?.username === "rogue";
        const idToken = await axiosService.getTgAuthToken(
          tgData.initDataRaw,
          isMocked,
          tgData.initData?.user?.photoUrl,
        ); // Fetch ID token
        if (idToken) {
          const { payload }: { payload: any } = decodeToken(idToken);

          await web3authSfa.connect({
            verifier,
            verifierId: payload.sub,
            idToken,
          });

          setAuthState((prev) => ({ ...prev, loggedIn: true }));
        }
      } catch (error) {
        console.error("Error during Web3Auth connection:", error);
      }
    }
  };

  const login = async () => {
    if (authState.web3AuthInitialized && tgData?.initDataRaw) {
      return connectWeb3Auth();
    }

    setAuthState((prev) => ({ ...prev, isModelToggle: true }));
  };

  const getUserInfo = async () => {
    const user = await web3authSfa.getUserInfo();
    console.log("user: ", user);
  };

  const logout = async () => {
    await web3authSfa.logout();
    setProvider(null);
    setAuthState((prev) => ({ ...prev, loggedIn: false }));
  };

  // Check the RPC file for the implementation
  const getAccounts = async () => {
    try {
      const address = await rpc.getAccounts();
      setWalletAddress(address);
    } catch (error) {
      console.error("provider not initialized yet");
      setAuthState((prev) => ({
        ...prev,
        isError: true,
      }));
    }
  };

  const getBalance = async () => {
    try {
      const balance = await rpc.getBalance();
      setTonBalance(balance);
    } catch (error) {
      console.error("provider not initialized yet");
      setAuthState((prev) => ({
        ...prev,
        isError: true,
      }));
    }
  };

  const signMessage = async () => {
    try {
      const signedMessage = await rpc.sendTransaction();
      console.log("signedMessage: ", signedMessage);
    } catch (error) {
      console.error("provider not initialized yet");
      setAuthState((prev) => ({
        ...prev,
        isError: true,
      }));
    }
  };

  const sendTransaction = async () => {
    try {
      console.log("Sending Transaction...");
      const transactionReceipt = await rpc.sendTransaction();
      console.log("transactionReceipt: ", transactionReceipt);
    } catch (error) {
      console.error("provider not initialized yet");
      setAuthState((prev) => ({
        ...prev,
        isError: true,
      }));
    }
  };

  return {
    provider,
    setProvider,
    authState,
    setAuthState,
    login,
    getUserInfo,
    logout,
    getAccounts,
    getBalance,
    signMessage,
    sendTransaction,
    initializeWeb3Auth,
    connectWeb3Auth,
  };
}

export default useWeb3Auth;
